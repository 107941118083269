/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import { Tabs, Tab, Col, Row } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const AFKGuidesRumble: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>AFK Journey Relentless Rumble</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_rumble.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>AFK Journey Relentless Rumble</h1>
          <h2>A guide for the Relentless Rumble event in AFK Journey.</h2>
          <p>
            Last updated: <strong>24/11/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Relentless Rumble" />
        <StaticImage
          src="../../../images/afk/generic/rumble.webp"
          alt="Guides"
        />
        <p>
          <strong>Relentless Rumble</strong> is a event/new mode released in the
          Season 2 of AFK Journey. In this guide you will find the best teams to
          use there!
        </p>
        <SectionHeader title="What’s the meta team?" />
        <p>
          Realistically speaking,{' '}
          <strong>
            your meta team will depend on your specific box, and your account
            focus
          </strong>
          , so use your highest rarity DPS carry characters, along with whoever
          can best boost their damage.
        </p>
        <p>
          Still, below you can find a few example teams. Big thanks to{' '}
          <StaticImage
            src="../../../images/afk/icons/afkanalytica.png"
            alt="AFK Analytica"
          />{' '}
          <strong className="yellow">AFK Analytica</strong> for the team
          building tool. You can find their website{' '}
          <a
            href="https://www.afkanalytica.com/"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>{' '}
          and their discord{' '}
          <a
            href="https://www.discord.gg/analytica"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
        <SectionHeader title="Best teams - Brawl 1" />
        <Tabs
          defaultActiveKey="Team1"
          transition={false}
          id="boss-tabs-season-n"
          className="nav-tabs"
        >
          <Tab
            eventKey="Team1"
            title={<>Team #1 - Best Team</>}
            className="with-bg"
          >
            <h5>Team #1 - Best Team</h5>
            <StaticImage
              src="../../../images/afk/dream_s/rumble_1_1.webp"
              alt="Guides"
            />
            <p>
              Credits to <strong>Kontrol</strong> from the AFK Analytica
              Discord!
            </p>
          </Tab>
        </Tabs>
        <SectionHeader title="Best teams - Brawl 2" />
        <Tabs
          defaultActiveKey="Team1"
          transition={false}
          id="boss-tabs-season-2"
          className="nav-tabs"
        >
          <Tab
            eventKey="Team1"
            title={<>Team #1 - Best Team</>}
            className="with-bg"
          >
            <h5>Team #1 - Best Team</h5>
            <StaticImage
              src="../../../images/afk/dream_s/rumble_2_1.webp"
              alt="Guides"
            />
            <p>
              Credits to <strong>North</strong> from the AFK Analytica Discord!
            </p>
          </Tab>
        </Tabs>
        <SectionHeader title="Best teams - Brawl 3" />
        <Tabs
          defaultActiveKey="Team1"
          transition={false}
          id="boss-tabs-season-3"
          className="nav-tabs"
        >
          <Tab eventKey="Team1" title={<>Team #1</>} className="with-bg">
            <h5>Team #1 </h5>
            <StaticImage
              src="../../../images/afk/dream_s/rumble_3_1.webp"
              alt="Guides"
            />
          </Tab>
          <Tab eventKey="Team2" title={<>Team #2 </>} className="with-bg">
            <h5>Team #2</h5>
            <StaticImage
              src="../../../images/afk/dream_s/rumble_3_2.webp"
              alt="Guides"
            />
          </Tab>
        </Tabs>
        <SectionHeader title="Videos" />
        <p>For more team ideas, check this video:</p>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="lyzUT_0VLoY" />
          </Col>
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesRumble;

export const Head: React.FC = () => (
  <Seo
    title="Relentless Rumble | AFK Journey | Prydwen Institute"
    description="A guide for the Relentless Rumble event in AFK Journey."
    game="afk"
  />
);
